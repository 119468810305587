export default {
  methods: {
    getMonthPayload(payload) {
      const date = payload || new Date();
      const y = date.getFullYear();
      const m = date.getMonth();
      const dateStart = new Date(y, m, 1);
      const dateEnd = new Date(y, m + 1, 0);
      return { dateStart, dateEnd };
    },
  },
};
