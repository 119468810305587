<template>
  <div class="metrics">
    <Search @updateData="updateData" />
    <MetricsCard
      :isAllClinicsView="isAllClinicsView"
      :metricsData="metricsData" />
    <MedicGraphics
      v-show="isMedicViewActive"
      @updateData="loadMedicGraphics"
      ref="medicGraphics" />
    <ABCGraphics
      v-show="isABCViewActive"
      @updateData="loadABCGraphics"
      ref="abcGraphics" />
    <Graphics
      v-show="canViewGraphGeneral"
      :selector="viewOption"
      :filterData="filterData" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import monthPayloadGenerator from "@/mixin/monthPayloadGenerator.mixin";

export default {
  name: "MetricsComponent",
  components: {
    MetricsCard: () => import("@/components/metrics/MetricsCard.vue"),
    MedicGraphics: () => import("@/components/metrics/Graphics/Medic.vue"),
    ABCGraphics: () => import("@/components/metrics/Graphics/ABC.vue"),
    Search: () => import("@/components/metrics/Search.vue"),
    Graphics: () => import("@/components/metrics/GraphicsGeneral.vue"),
  },
  mixins: [monthPayloadGenerator],
  data() {
    return {
      filterData: {},
      metricsData: [],
      viewOption: "medic",
      isAllClinicsView: false,
    };
  },
  mounted() {
    const setOptionViewByUserType = {
      superadmin: "medic",
      manager: "medic",
      admin: "medic",
      abc: "abc",
      manager_abc: "abc",
      medic: "medic",
      cacit: "cacit",
      manager_cacit: "cacit",
    };
    this.viewOption = setOptionViewByUserType[this.getUserType];
  },
  computed: {
    ...mapGetters(["getUserType", "isABCUserType", "getClinic"]),
    isNotCacit() {
      return (
        this.getUserType !== "cacit" && this.getUserType !== "manager_cacit"
      );
    },
    isSuperUser() {
      return this.getUserType === "superadmin" || this.getUserType === "admin";
    },
    canViewGraphGeneral() {
      const canViewGraphGeneral = [
        "superadmin",
        "admin",
        "manager",
        "cacit",
        "manager_cacit",
      ];
      return canViewGraphGeneral.includes(this.getUserType);
    },
    isABCViewActive() {
      const canViewABCGraphics = [
        "superadmin",
        "admin",
        "manager",
        "abc",
        "manager_abc",
      ];
      return (
        canViewABCGraphics.includes(this.getUserType) &&
        this.viewOption === "abc"
      );
    },
    isMedicViewActive() {
      const canViewMedicGraphics = ["superadmin", "admin", "manager", "medic"];
      return (
        canViewMedicGraphics.includes(this.getUserType) &&
        this.viewOption === "medic"
      );
    },
  },
  methods: {
    loadABCGraphics() {
      const payload = this.getPayload();
      if (
        this.isNotCacit &&
        (this.isABCUserType || this.viewOption === "abc")
      ) {
        this.$refs.abcGraphics.loadData(payload, true);
      }
    },
    loadMedicGraphics() {
      const payload = this.getPayload();
      if (
        this.isNotCacit &&
        (this.getUserType === "medic" || this.viewOption === "medic")
      ) {
        this.$refs.medicGraphics.loadData(payload, true);
      }
    },
    updateData(filterData, onMounted = false) {
      const { date, viewOption, isAllClinicsActive } = filterData;
      this.isAllClinicsView = isAllClinicsActive;
      this.viewOption = viewOption;
      this.loadData(date, onMounted);
    },
    loadData(date = null, onMounted = false) {
      const payload = this.getPayload(date);
      if (!this.isNotCacit || this.viewOption === "cacit") {
        this.getBoxesCacitData(payload);
        this.getGraphCacitData(payload);
        if (!this.isSuperUser || !this.getUserType === "manager") return;
      }
      if (this.getUserType === "medic" || this.viewOption === "medic") {
        this.getBoxesMedicData(payload);
        if (!onMounted) {
          this.$refs.medicGraphics.loadData(payload, onMounted);
        }
        if (this.isSuperUser || this.getUserType === "manager") {
          this.getGraphMedicData(payload);
        }
        if (!this.isSuperUser || this.getUserType === "manager") return;
      }
      if (this.isABCUserType || this.viewOption === "abc") {
        this.getBoxesMedicData(payload);
        if (!onMounted) {
          this.$refs.abcGraphics.loadData(payload, onMounted);
        }
      }
    },
    getPayload(date = null) {
      let payload = {};
      payload = this.getMonthPayload(date);
      if (!this.allClinics) {
        payload.clinic_id = this.getClinic;
      }
      return payload;
    },
    getBoxesMedicData(payload) {
      this.$api.metrics.getDataBoxesMedic(payload).then((res) => {
        this.metricsData = [
          {
            name: "Total Confirmadas",
            total: res.data.total,
            total_percentage: res.data.total_percentage,
          },
        ];
        if (res.data.clinics.length === 0) {
          this.metricsData.push(
            ...[
              {
                first_visit: 0,
                first_visit_percentage: 0,
                id: 3,
                name: "Ciudad de México",
                online: 0,
                online_percentage: 0,
                second_visit: 0,
                second_visit_percentage: 0,
                total: 0,
                total_percentage: 0,
              },
              {
                first_visit: 0,
                first_visit_percentage: 0,
                id: 2,
                name: "Puebla",
                online: 0,
                online_percentage: 0,
                second_visit: 0,
                second_visit_percentage: 0,
                total: 0,
                total_percentage: 0,
              },
              {
                first_visit: 0,
                first_visit_percentage: 0,
                id: 1,
                name: "Monterrey",
                online: 0,
                online_percentage: 0,
                second_visit: 0,
                second_visit_percentage: 0,
                total: 0,
                total_percentage: 0,
              },
            ]
          );
        } else {
          this.metricsData.push(...res.data.clinics);
        }
      });
    },
    getGraphMedicData(payload) {
      this.$api.metrics.getDataMedic(payload).then((res) => {
        this.filterData = res.data;
      });
    },
    getBoxesCacitData(payload) {
      this.$api.metrics.getDataBoxesCacit(payload).then((res) => {
        this.metricsData = [
          {
            name: "Total Confirmadas",
            total: res.data.total,
            total_percentage: res.data.total_percentage,
          },
        ];
        if (res.data.clinics.length === 0) {
          this.metricsData.push(
            ...[
              {
                first_visit: 0,
                first_visit_percentage: 0,
                id: 3,
                name: "Ciudad de México",
                online: 0,
                online_percentage: 0,
                second_visit: 0,
                second_visit_percentage: 0,
                total: 0,
                total_percentage: 0,
              },
              {
                first_visit: 0,
                first_visit_percentage: 0,
                id: 2,
                name: "Puebla",
                online: 0,
                online_percentage: 0,
                second_visit: 0,
                second_visit_percentage: 0,
                total: 0,
                total_percentage: 0,
              },
              {
                first_visit: 0,
                first_visit_percentage: 0,
                id: 1,
                name: "Monterrey",
                online: 0,
                online_percentage: 0,
                second_visit: 0,
                second_visit_percentage: 0,
                total: 0,
                total_percentage: 0,
              },
            ]
          );
        } else {
          this.metricsData.push(...res.data.clinics);
        }
      });
    },
    getGraphCacitData(payload) {
      this.$api.metrics.getDataCacit(payload).then((res) => {
        this.filterData = res.data;
      });
    },
    allClinicsChange(newValue) {
      this.allClinics = newValue;
      this.loadData();
    },
  },
};
</script>
